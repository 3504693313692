<template>
  <div>
    <HeaderBox>
      <template #title> 新增证书 </template>
    </HeaderBox>
    <div class="tab">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="8">
            <a-form-model-item ref="name" label="姓名" prop="name">
              <a-input v-model="form.name" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="手机号 " prop="mobile">
              <a-input v-model="form.mobile" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="证件信息 " prop="idcard">
              <a-input v-model="form.idcard" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="职称/职务" prop="occupationName">
              <a-select
                v-model="form.occupationName"
                placeholder="please select your zone"
              >
                <a-select-option
                  :value="item.name"
                  v-for="item in jobList"
                  :key="item.tempId"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="学历" prop="degree">
              <a-select v-model="form.degree" placeholder="请选择学历">
                <a-select-option
                  :value="item"
                  v-for="item in gradeList"
                  :key="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="工作单位 " prop="work">
              <a-input v-model="form.work" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="出生日期" prop="birthday">
              <a-date-picker
                format="YYYY-MM-DD"
                placeholder="出生日期"
                v-model="form.birthday"
                @change="onChange"
                @ok="onOk"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="发证日期 " prop="getcertifTime">
              <a-date-picker
                format="YYYY-MM-DD"
                placeholder="发证日期"
                v-model="form.getcertifTime"
                @change="onChange"
                @ok="onOk"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="证书编号 " prop="code">
              <a-input v-model="form.code" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="证书名称" prop="certId">
              <a-select
                v-model="form.certId"
                placeholder="please select your zone"
                @change="onclick"
              >
                <a-select-option
                  :value="item.tempId"
                  v-for="item in tempList"
                  :key="item.tempId"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="8">
            <a-form-model-item label="获证来源 " prop="certifSource">
              <a-input v-model="form.certifSource" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-model-item label="证件照">
              <a-upload
                name="photo"
                list-type="picture-card"
                :showUploadList="false"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
                :beforeUpload="beforeUpload"
                :customRequest="customRequest"
              >
                <img
                  v-if="form.photo"
                  :src="form.photo"
                  style="max-width: 180px"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="证书图片">
              <a-upload
                name="url"
                list-type="picture-card"
                :showUploadList="false"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG"
                :beforeUpload="beforeUpload"
                :customRequest="customRequest"
              >
                <img
                  v-if="form.url"
                  :src="form.url"
                  style="max-width: 180px"
                  alt="avatar"
                />
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8" style="margin-left: 60px">
            <a-button type="primary" @click="onSubmit" :loading="uploading"
              >提交
            </a-button>
            <a-button style="margin-left: 10px" @click="$router.go(-1)">
              取消
            </a-button>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
  </div>
</template>


<script>
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  data() {
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      other: "",
      form: {
        name: "",
        mobile: "",
        idcard: "",
        degree: "",
        work: " ",
        occupationName: "",
        birthday: "",
        getcertifTime: "",
        code: "",
        type: "",
        certifSource: "",
        certifName: "",
        certId: "",
        certId: "",
        photo: "",
        url:"",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        certId: [
          {
            required: true,
            message: "请选择证书模板",
            trigger: "change",
          },
        ],
        idcard: [
          {
            required: true,
            message: "请输入身份证号码",
            trigger: "change",
          },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确的身份证号码",
            trigger: "blur",
          },
        ],
        birthday: [
          {
            required: true,
            message: "请输入出生日期",
            trigger: "change",
          },
        ],
        getcertifTime: [
          {
            required: true,
            message: "请输入发证日期",
            trigger: "change",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入证书编号",
            trigger: "change",
          },
        ],
      },
      jobList: [], //职务
      tempList: [], // 证书模板
      gradeList: [
        "小学",
        "初中",
        "中专",
        "高中",
        "大专",
        "本科",
        "硕士研究生",
        "博士研究生",
      ], // 学历
      loading: false, // 列表加载
      uploading: false, // 提交加载
    };
  },
  methods: {
    // 时间选择
    onChange(value, dateString) {
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
    },
    onOk(value) {
      console.log("onOk: ", value);
    },
    // 获取对应id下的证书名称
    onclick(e) {
      this.tempList.forEach((element) => {
        if (element.tempId == e) {
          this.form.certifName = element.name;
          this.form.type = element.type;
        }
      });
    },
    // 提交
    onSubmit() {
      if (this.uploading) {
        return;
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.uploading = true;
          this.$ajax({
            url: "/hxclass-management/importCertificate/add",
            method: "POST",
            params: this.form,
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.$message.success(res.message);
              this.$router.go(-1);
            } else {
              this.$message.warning(res.message);
              // this.$router.go(-1);
            }
            this.uploading = false;
          });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 上传前钩子上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },


    // 文件上传
    customRequest(fileData) {
      this.loading = true;
      const name = fileData.filename // 名称前缀
      const bucket = 'user/signup/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.form[name] = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },
    // 获取职务
    getjob() {
      this.$ajax({
        url: "/hxclass-management/job/list",
        method: "get",
        params: {
          pageNum: 1,
          pageSize: 11000,
        },
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.jobList = res.data.records;
        }
      });
    },
    // 集合列表
    getTempid() {
      this.$ajax({
        url: "/hxclass-management/importCertificate/select/certif-list",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tempList = res.data;
        }
      });
    },
  },
  mounted() {
    this.getjob();
    this.getTempid();
  },
};
</script>
<style lang="less" scoped>
.tab {
  padding: 40px 20px;
  overflow: hidden;
  min-width: 1200px;
}
</style>